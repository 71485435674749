import React, { Component } from "react"
import { css } from '@emotion/react'
import { navigate } from "../../../helpers/queryNavigate"
import PropTypes from 'prop-types'

// FINANCIERA COMPONENTS
import { CardElevation,  ButtonDegrade, MaterialInput } from '@findep/mf-landings-core'

//MATERIAL UI COMPONENTS 
import { FormLabel, FormControl, RadioGroup, Radio, Snackbar, Backdrop, Grid, Container, FormControlLabel } from "@material-ui/core"
import Typography from '@material-ui/core/Typography';

import { Alert } from '@material-ui/lab';

//MODULES PROPS SYSTEM
// import AvisosApp from "../../elements/AvisosApp.compilable"
import replaceAcents from '../../../helpers/replaceAcents'

//ICONS MATERIAL UI
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CircularProgress from '@material-ui/core/CircularProgress';

//SERVICIOS 
import { withThemeProps } from "@findep/microfronts-core"
import axios from 'axios'

const bDrop = css`  
    z-index:9999;
    color: '#fff';
  `

const root = css`
  flex-grow: 1;
`
const buttonCss = css`
    display: flex;
    justify-content: flex-center;
`

class ContactoCmp extends Component {
  constructor(props) {
    super(props);
    this.state = {      
      nombre: '',      
      apellidoPaterno: '',
      apellidoMaterno: '',
      telefono: '',
      celular: '',
      email: '',
      ciudad: '',
      estado: '',
      num_contrato:'',
      is_client:'',
      contrato_mot:'',
      comentarios:'',
      acept:'',
      
      btnSubmit: false,
      errorService: false,
      bdHabilita: false,

      _errors: {

        nombre: {
          error: false,
          errorType: ' ',
        },
        apellidoPaterno: {
          error: false,
          errorType: '',
        },
        apellidoMaterno: {
          error: false,
          errorType: '',
        },
        email: {
          error: false,
          errorType: this.props.inputErrorMessageEmail,
        },
        telefono: {
          error: false,
          errorType: this.props.inputHelperTextPhone,
        },
        celular: {
          error: false,
          errorType: this.props.inputHelperTextPhone,
        },
        ciudad: {
          error: false,
          errorType: ' ',
        },
        estado: {
          error: false,
          errorType: ' ',
        },
        num_contrato:{
          error: false,
          errorType: this.props.inputHelperTextPhone,
        }

      }
      
    };

    this.handleChange = this.handleChange.bind(this)
    this._handleError = this._handleError.bind(this)    
    
  }

  //VERIFICA SI SE CUMPLEN TODAS LA CONDICIONES PARA ENVIAR LA INFORMACION
  componentDidUpdate(prevProp, prevState) {
    const {
      nombre,      
      apellidoPaterno,
      apellidoMaterno,
      telefono,
      celular,
      email,
      ciudad,
      estado,
      num_contrato,
      is_client,
      contrato_mot,
      comentarios,
      acept
    } = this.state    

    if (
      nombre !== '' && 
      apellidoPaterno !== '' &&
      apellidoMaterno !== '' &&
      email !== '' &&
      telefono !== '' &&
      celular !== '' &&
      ciudad !== '' &&
      estado !== '' &&
      num_contrato !== '' &&
      is_client !== '' &&
      contrato_mot !== '' &&
      comentarios !== '' &&
      acept !== '' 
    ) {
      if (!prevState.btnSubmit) {
        this.setState({ btnSubmit: true })
      }
    } else if (prevState.btnSubmit) {
      this.setState({ btnSubmit: false })
    }

   

  }


  //GUARDA LOS ESTADOS DE LOS INPUTS
  handleChange(prop, data) {

    const state = {
      [prop]: data.value
    }
    
    this._handleError(prop, {}, false)
    const tmp = Object.assign({}, this.state, state)
    this.setState(tmp, () => {    })

    // console.log("estoy en handleChange: - " + prop+" - "+ this.state.nombre); 
  }

   _handleChange(event) {
    const state = {
      comentarios: event.target.value
    }
    const tmp = Object.assign({}, this.state, state)
    this.setState(tmp, () => {})
  }

  _handleError(prop, data, bool = true) {
    let { _errors } = this.state
    _errors[prop] = {
      ..._errors[prop],
      error: bool
    }
    this.setState({ _errors })

  }

 
  //FUNCION QUE ENVIA INFORMACION AL SERVICIO
  async submitStepTwo() {
    this.setState({ btnSubmit: false, bdHabilita: true })
      const {
      nombre,      
      apellidoPaterno,
      apellidoMaterno,
      telefono,
      celular,
      email,
      ciudad,
      estado,
      num_contrato,
      is_client,
      contrato_mot,
      comentarios,
      acept
    } = this.state  

    let castNombre = replaceAcents(nombre)    
    let castApellidoPaterno = replaceAcents(apellidoPaterno)
    let castApellidoMaterno = replaceAcents(apellidoMaterno)   
        
    let navigateUrl = this.props.pageContext?.next?.path
     const info = {
                apeMaterno: castApellidoPaterno,
                apePaterno: castApellidoMaterno,
                comentarios: comentarios,
                credito: is_client,
                cuenta: num_contrato,
                delegacion: ciudad,
                email: email,
                estado: estado,
                motivoUno: contrato_mot,
                priNombre: castNombre,
                sms: acept,
                telefonoDos: celular,
                telefonoUno: telefono
            };
            // .then(response => { console.log("response.data=>", response.data) });

    axios.post('https://www.apoyo-financiero.com/AfiServices/afiwebservices/afiSite/registrarContacto', info)
      .then((response) => {
        console.log("response.data=>", response.data)
        navigate(navigateUrl)
      })
      .catch(error => {
        console.log(error)
        this.setState({ errorService: true, btnSubmit: true, bdHabilita: false })
      })
  }


    
  render() {
    const {
      titleIcon = "Contacto",      
      inputLabelName = "Nombre(s)",
      inputLabelPaternalLastName = "Apellido paterno",
      inputLabelMaternalLastName = "Apellido materno",      
      inputLabelPhone = "Teléfono",      
      inputLabelCell = "Celular",    
      inputLabelEmail = "Correo Electrónico",
      inputErrorMessageEmail = "Debe ingresar un correo eléctronico válido",      
      buttonDegradeTextButton = "Enviar",      
      alertMessage = "Lo sentimos, por el momento el servicio no se encuentra disponible",            
      RadioLabelYes= "Si",
      RadioLabelNo= "No",
      LabelIsClient="¿Es cliente?",
      LabelNumContacto="Numero de contrato",
      LabelMotContacto="¿ Motivo de contacto ?",
      LabelRadioSugerencia="Sugerencia",
      LabelRadioQueja="Queja",
      LabelRadioPregunta="Pregunta",
      LabelAceptInfo="Acepto expresamente recibir información comercial y publicitaria de Apoyo Financiero Inc. a través de correos electrónicos y mensajes SMS, entendiendo que Apoyo Financiero Inc. no cede ni transfiere datos personales a ninguna empresa ni a terceros.",
      textInformativo=<span>Para reportar cualquier comentario, inquietud o queja, puede hacerlo por escrito a: Servicio al Cliente AFI a la dirección: 2300 Clayton Road,
                          Suite 1170 Concord, California 94520.<br/> Por teléfono al 1-800-891-2778 (marcar la opción "1" CLIENTE) o por correo electrónico a la cuenta 
                          &nbsp;<a href="mailto:contact@apoyofin.com" rel="nofollow noopener noreferrer" style={{color:"rgb(53, 72, 16)"}}>contact@apoyofin.com</a></span>,
      LabelComentarios="Comentarios",
      LabelCiudad="Ciudad",
      LabelEstado="Estado"
    } = this.props


    return (
      <Container justify="center" >
        <Grid container justify="left">
                    <Grid item style={{ textAlign: "left" }}>                        
                        <Typography component='h3' variant="h4" >
                        <strong style={{color:"#698d21"}}>{titleIcon}</strong>                        
                        </Typography>                        
                        
                    </Grid>
                </Grid>
      <br/><br/>

        <Grid container spacing={2}>
          <Grid item xs={12} >

            <CardElevation  >
              <div css={root}>
                <Grid container spacing={2}>

                  <Grid item xs={1} sm={1}>
                    <PersonIcon />
                  </Grid>
                  <Grid item xs={11} sm={5}>
                    <MaterialInput
                      type="text"
                      id="contacto-nombre"
                      name="contacto-nombre-input"
                      label={inputLabelName}
                      value={this.state.nombre}
                      pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                      accept=" A-ZÑÁÉÍÓÚÜ"
                      transform={(value) => value.toUpperCase().slice(0, 60)}
                      error={this.state._errors.nombre.error}
                      onError={(error) => this._handleError('nombre', error)}
                      errorMessage={this.state._errors.nombre.errorType}
                      onChange={(_event, component) => this.handleChange('nombre', component)}
                      required                      
                    />
                  </Grid>


                  <Grid item xs={1} sm={1}>
                  </Grid>
                  <Grid item xs={11} sm={5}>
                    <MaterialInput
                      id="contacto-apellido-paterno"
                      name="contacto-apellido-paterno-input"
                      type="text"
                      label={inputLabelPaternalLastName}
                      value={this.state.apellidoPaterno}
                      pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                      accept=" A-ZÑÁÉÍÓÚÜ"
                      transform={(value) => value.toUpperCase().slice(0, 50)}
                      onError={(error) => this._handleError('apellidoPaterno', error)}
                      errorMessage={this.state._errors.apellidoPaterno.errorType}
                      onChange={(_event, component) => this.handleChange('apellidoPaterno', component)}
                      required={true}
                      error={this.state._errors.apellidoPaterno.error}
                      disabled={this.state._errors.nombre.error || this.state.nombre === ''}                      
                    />
                  </Grid>

                  <Grid item xs={1} sm={1}>
                  </Grid>
                  <Grid item xs={11} sm={5}>
                    <MaterialInput
                      id="contacto-apellido-materno"
                      name="contacto-apellido-materno-input"
                      type="text"
                      label={inputLabelMaternalLastName}
                      value={this.state.apellidoMaterno}
                      pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                      accept=" A-ZÑÁÉÍÓÚÜ"
                      transform={(value) => value.toUpperCase().slice(0, 50)}
                      onChange={(_event, component) => this.handleChange('apellidoMaterno', component)}
                      required
                      error={this.state._errors.apellidoMaterno.error}
                      disabled={this.state._errors.apellidoPaterno.error || this.state.apellidoPaterno === ''}
                    />
                  </Grid>

                  <Grid item xs={1} sm={1}>                  
                  </Grid>
                  <Grid item xs={11} sm={5}>
                    <MaterialInput
                      id="contacto-correo-electronico"
                      name="contacto-correo-electronico-input"
                      type="email"
                      label={inputLabelEmail}
                      value={this.state.email}
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      accept="\w@._\-"
                      transform={(value) => value.slice(0, 50)}
                      className="is-mobile ml10 pb15 is-4 expand"
                      onChange={(_event, component) => this.handleChange('email', component)}
                      onError={(error) => this._handleError('email', error)}
                      required={true}
                      error={this.state._errors.email.error}
                      disabled={this.state._errors.apellidoMaterno.error || this.state.apellidoMaterno === ''}
                      errorMessage={inputErrorMessageEmail}
                      inputmode="email"
                      autocomplete="email"
                    />
                  </Grid>


                  <Grid item xs={1} sm={1}> 
                    <PhoneIcon />
                  </Grid>
                  <Grid item xs={11} sm={5}>
                    <MaterialInput
                      id="contacto-telefono"
                      name="contacto-telefono-input"
                      type="tel"
                      inputmode="tel"
                      autocomplete="tel"
                      pattern="^([2-9])(?!0123456789|1234567890|0987654321|9876543210|\1{9})\d{9}$"
                      accept="0-9"
                      label={inputLabelPhone}
                      value={this.state.telefono}
                      transform={(value) => value.slice(0, 10)}
                      onChange={(_event, component) => this.handleChange('telefono', component)}
                      onError={(error) => this._handleError('telefono', error)}
                      errorMessage={this.state._errors.telefono.errorType}
                      required={true}
                      error={this.state._errors.telefono.error}
                      disabled={this.state._errors.email.error || this.state.email === ''}
                    />
                  </Grid>

                  <Grid item xs={1} sm={1}>              
                  </Grid>
                  <Grid item xs={11} sm={5}>
                    <MaterialInput
                      id="contacto-celurar"
                      name="contacto-celurar-input"
                      type="tel"
                      inputmode="tel"
                      autocomplete="tel"
                      pattern="^([2-9])(?!0123456789|1234567890|0987654321|9876543210|\1{9})\d{9}$"
                      accept="0-9"
                      label={inputLabelCell}
                      value={this.state.celular}
                      transform={(value) => value.slice(0, 10)}
                      onChange={(_event, component) => this.handleChange('celular', component)}
                      onError={(error) => this._handleError('celular', error)}
                      errorMessage={this.state._errors.celular.errorType}
                      required={true}
                      error={this.state._errors.celular.error}
                      disabled={this.state._errors.telefono.error || this.state.telefono === ''}                      
                    />
                  </Grid>

                  <Grid item xs={1} sm={1}>                    
                  </Grid>
                  <Grid item xs={11} sm={5}>
                    <MaterialInput
                      type="text"
                      id="contacto-ciudad"
                      name="contacto-ciudad-input"
                      label={LabelCiudad}
                      value={this.state.ciudad}
                      pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                      accept=" A-ZÑÁÉÍÓÚÜ"
                      transform={(value) => value.toUpperCase().slice(0, 60)}
                      error={this.state._errors.ciudad.error}
                      onError={(error) => this._handleError('ciudad', error)}
                      errorMessage={this.state._errors.ciudad.errorType}
                      onChange={(_event, component) => this.handleChange('ciudad', component)}
                      required
                      disabled={this.state._errors.celular.error || this.state.celular === ''}                      
                    />
                  </Grid>


                  <Grid item xs={1} sm={1}>
                  </Grid>
                  <Grid item xs={11} sm={5}>
                    <MaterialInput
                      id="contacto-estado"
                      name="contacto-estado-input"
                      type="text"
                      label={LabelEstado}
                      value={this.state.estado}
                      pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                      accept=" A-ZÑÁÉÍÓÚÜ"
                      transform={(value) => value.toUpperCase().slice(0, 50)}
                      onError={(error) => this._handleError('estado', error)}
                      errorMessage={this.state._errors.estado.errorType}
                      onChange={(_event, component) => this.handleChange('estado', component)}
                      required={true}
                      error={this.state._errors.estado.error}
                      disabled={this.state._errors.ciudad.error || this.state.ciudad === ''}                      
                    />
                  </Grid>



                  <Grid item xs={1} sm={1}>              
                  </Grid>
                  <Grid item xs={11} sm={5}>
                    <FormControl component="fieldset" >
                      <FormLabel component="legend" >{LabelIsClient}</FormLabel>
                      <RadioGroup aria-label="is-client" name="is-client" value={this.value} onChange={(_event, component) => this.handleChange("is_client", { value: _event.target.value })} row style={{ marginLeft: '30px' }}>
                        <FormControlLabel 
                          value="S" 
                          control={<Radio id="is-client-yes" name="is-client-yes"/>} 
                          label={RadioLabelYes}  
                          checked={this.state.is_client === 'S'}
                          disabled={this.state._errors.estado.error || this.state.estado === ""} 
                        />
                        <FormControlLabel 
                          value="N" 
                          control={<Radio id="is-client-no" name="is-client-no"/>} 
                          label={RadioLabelNo}  
                          checked={this.state.is_client === 'N'}
                          disabled={this.state._errors.estado.error || this.state.estado === ""} 
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={1} sm={1}>              
                  </Grid>
                  <Grid item xs={11} sm={5}>
                    <MaterialInput
                      id="contacto-numero-contrato"
                      name="contacto-numero-contrato-input"
                      type="tel"
                      inputmode="tel"
                      autocomplete="tel"
                      pattern="^([2-9])(?!0123456789|1234567890|0987654321|9876543210|\1{9})\d{9}$"
                      accept="0-9"
                      label={LabelNumContacto}
                      value={this.state.num_contrato}
                      transform={(value) => value.slice(0, 10)}
                      onChange={(_event, component) => this.handleChange('num_contrato', component)}
                      onError={(error) => this._handleError('num_contrato', error)}
                      errorMessage={this.state._errors.num_contrato.errorType}
                      required
                      error={this.state._errors.num_contrato.error}
                      disabled={this.state.is_client !== "S"}                      
                    />
                  </Grid>


                  <Grid item xs={1} sm={1}>              
                  </Grid>
                  <Grid item xs={11} sm={5}>
                    <FormControl component="fieldset" >
                      <FormLabel component="legend" >{LabelMotContacto}</FormLabel>
                      <RadioGroup aria-label="motivo-contact" name="motivo-contact" value={this.value} onChange={(_event, component) => this.handleChange("contrato_mot", { value: _event.target.value })} row style={{ marginLeft: '30px' }}>
                        <FormControlLabel 
                          value={LabelRadioQueja} 
                          control={<Radio id="motivo-contact-queja" name="motivo-contact-queja"/>} 
                          label={LabelRadioQueja}  
                          disabled={this.state.is_client === ""} 
                        />
                        <FormControlLabel 
                            value={LabelRadioPregunta} 
                            control={<Radio id="motivo-contact-pregunta" name="motivo-contact-pregunta"/>} 
                            label={LabelRadioPregunta}  
                            disabled={this.state.is_client === ""} 
                        />
                        <FormControlLabel 
                          value={LabelRadioSugerencia} 
                          control={<Radio id="motivo-contact-sugerencia" name="motivo-contact-sugerencia" />} 
                          label={LabelRadioSugerencia}  
                          disabled={this.state.is_client === ""}                         
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={1} sm={1}>              
                  </Grid>
                  <Grid item xs={11} sm={5}>
                    <textarea
                        aria-label={LabelComentarios}
                        rows={5}
                        id="contacto-comentarios-input"
                        name="contacto-comentarios-input"                        
                        placeholder={LabelComentarios}
                        style={{ width: "100%" }}
                        value={this.state.comentarios}
                        onChange={(event) => {this._handleChange(event)}}                        
                        disabled={this.state.contrato_mot === ""}    
                                                                        
                      />
                      <br/>&nbsp;
                  </Grid>

                  <Grid item xs={1} sm={1}>             
                  </Grid>
                  <Grid item xs={10} sm={10}>                      
                        <FormControl component="fieldset" >
                      <FormLabel component="legend" >{LabelAceptInfo}</FormLabel>
                      <RadioGroup aria-label="contacto-is-acept" name="contacto-is-acept" value={this.value} onChange={(_event, component) => this.handleChange("acept", { value: _event.target.value })} row style={{ marginLeft: '30px' }}>
                        <FormControlLabel 
                          value="S" 
                          control={<Radio id="contacto-is-acept-yes" name="contacto-is-acept-yes"/>} 
                          label={RadioLabelYes}  
                          checked={this.state.acept === 'S'}
                          disabled={this.state.comentarios === ""} 
                        />
                        <FormControlLabel 
                            value="N" 
                            control={<Radio id="contacto-is-acept-no" name="contacto-is-acept-no" />} 
                            label={RadioLabelNo}  
                            checked={this.state.acept === 'N'}
                            disabled={this.state.comentarios === ""}                         
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1} sm={1}>              
                  </Grid>
                                                    

                </Grid>

              <br/><br></br>
              <Grid container justify="center">
                  <Grid item >
                    <div css={buttonCss} >
                      <ButtonDegrade
                        id="contacto-continuar-solicitud-button"
                        icon={ArrowForwardIcon}
                        textButton={buttonDegradeTextButton}
                        onClick={() => this.submitStepTwo()}
                        disabled={!this.state.btnSubmit}
                      />
                    </div>
                  </Grid>
                  
                </Grid>
                <br/>&nbsp;
                <Grid item xs={12}>
                  <div css={buttonCss} >
                    <Typography component='subtitle' variant="subtitle" align="center" style={{ marginLeft: '15px' }} >
                      {textInformativo}                          
                    </Typography> 
                    </div>
                </Grid>

              </div>              
            </CardElevation>
            <br />
          </Grid>
          
        </Grid>

        <div style={{ padding: "20px 20px 20px 20px" }}>&nbsp;<br /><br />&nbsp;</div>       

        <Backdrop css={bDrop} open={this.state.bdHabilita} >
          <CircularProgress color="primary" />
        </Backdrop>
        <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
          <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
            {alertMessage}
          </Alert>
        </Snackbar>

      </Container>
    )
  }
}

ContactoCmp.propTypes = {
  titleIcon: PropTypes.string,     
  inputLabelName: PropTypes.string,
  inputLabelPaternalLastName: PropTypes.string,
  inputLabelMaternalLastName: PropTypes.string,
  inputLabelPhone: PropTypes.string,
  inputLabelCell: PropTypes.string,
  inputLabelEmail: PropTypes.string,
  inputErrorMessageEmail: PropTypes.string,
  buttonDegradeTextButton: PropTypes.string,
  alertMessage: PropTypes.string,
  RadioLabelYes: PropTypes.string,
  RadioLabelNo: PropTypes.string,
  LabelIsClient: PropTypes.string,
  LabelNumContacto: PropTypes.string,
  LabelMotContacto: PropTypes.string,
  LabelRadioSugerencia: PropTypes.string,
  LabelRadioQueja: PropTypes.string,
  LabelRadioPregunta: PropTypes.string,
  LabelAceptInfo: PropTypes.string,
  textInformativo: PropTypes.string,                      
  LabelComentarios: PropTypes.string
}

export default withThemeProps(ContactoCmp, 'VDPNContacto')


